@tailwind base;
@tailwind components;
@tailwind utilities;

/* This is in the tailwindcss preflight, and necessary for the borderstyles to
* work. For interoperability with MUI, we're disabling preflight, so we have to
* re-add some resets here manually.  */
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#root {
    min-height: 100%;
    min-width: 100%;
}

p,
label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
}

input, select {
    font-family: inherit;
    font-size: inherit;
}
